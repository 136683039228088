import React from 'react';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import LoadingGif from '../../../Images/loading.gif'

import classes from './LoadingScreen.module.css';


const LoadingScreen = (props) => {

    return (
        <div className={classes.LoadingScreen} >
            <img src={LoadingGif} className={classes.LoadingIcon} />
        </div>
    );

};

export default LoadingScreen;