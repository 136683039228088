import React from 'react';
import { TextField } from '@material-ui/core';

import classes from './Input.module.css';


const input = ( props ) => {
    
    const inputClasses = [classes.InputElement];

    if (props.invalid) {
        inputClasses.push(classes.Invalid);
    }

    return (
        <TextField {...props} size="small" value={props.value} className={inputClasses.join(' ')} id={props.id} label={props.label} variant="outlined" />
    );

};

export default input;