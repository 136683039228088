import React from 'react';
import classes from './MediaCard.module.css';
import DeleteIcon from '@material-ui/icons/Delete';


export default function MediaCard(props) {
    // const classes = useStyles();
    const className = (props.isSelected) ? classes.selected : classes.notSelectedCard;

    return (
        <div {...props} className={`${classes.card} ${className}`}>
            <img
                className={classes.media}
                src={props.src}
            />
            {!props.hideDeleteIcon && <DeleteIcon className={classes.deleteIcon} color="secondary" onClick={props.deleteHandler} />}
        </div>
    );
}