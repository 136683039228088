import './App.css';
import { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import LoginForm from './Pages/LoginForm/LoginForm';
import Home from './Pages/Home/Home';
import AddCard from './Pages/AddCard/AddCard';
import ManageOccasions from './Pages/ManageOccasions/ManageOccasions';
import MenuBar from './Templates/Components/MenuBar';
import LoadingScreen from './Templates/Components/LoadingScreen/LoadingScreen';
import AuthContext from './store/auth-context';
import GlobContext from './store/glob-context';

function App() {

  const authContext = useContext(AuthContext);
  const globContext = useContext(GlobContext);

  let primaryColor = "#333333";
  let secondaryColor = "#00a9e0";
  if (authContext.isLoggedIn){
    console.log("AuthContext.userInfo", authContext.userInfo);
    primaryColor = authContext.userInfo.primaryColor ? "#" + authContext.userInfo.primaryColor : primaryColor;
    secondaryColor = authContext.userInfo.secondaryColor ? "#" + authContext.userInfo.secondaryColor : secondaryColor;
  }
  console.log("primaryColor",primaryColor);
  const theme = createTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
    },
  });

  const config = {
    isLoggedIn: authContext.isLoggedIn,
    logoutHandler: authContext.logout,
    userInfo: authContext.userInfo
  }

  console.log('context',authContext);
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <header>
            {globContext.loading && <LoadingScreen/>}
            <MenuBar config={config}/>
            <Switch>
              {!authContext.isLoggedIn && (<Route path="/" component={LoginForm} exact />)}
              {authContext.isLoggedIn && (<Route path="/" component={Home} exact />)}
              {authContext.isLoggedIn && (<Route path="/Home" component={Home} exact />)}
              {authContext.isLoggedIn && (<Route path="/AddCard" component={AddCard} exact />)}
              {authContext.isLoggedIn && (<Route path="/ManageOccasions" component={ManageOccasions} exact />)}
              <Route path="/*" component={LoginForm} exact />
            </Switch>
          </header>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
