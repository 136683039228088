import React, {useState, useEffect, useContext} from 'react';
import AuthContext from '../../store/auth-context';
import GlobContext from '../../store/glob-context';
import MUIButton from '@material-ui/core/Button';
import ButtonGroup from '../../Templates/Components/ButtonGroup/ButtonGroup';
import BoxLabel from '../../Templates/Components/BoxLabel/BoxLabel';
import Input from '../../Templates/Components/Input/Input';
import Button from '../../Templates/Components/Button/Button';
import classes from './ManageOccasions.module.css';
import UploadInput from '../../Templates/Components/UploadInput/UploadInput';
import { APIsURL} from '../../Constants';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

// material UI table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextField from '@mui/material/TextField';

const Home = () => {

    const authContext = useContext(AuthContext);
    const globContext = useContext(GlobContext);
    const [occasions, setOccasions] = useState([]);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [date, setDate] = useState("2021-07-19");
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        fetchOccasions();
    }, []);

    const fetchOccasions = (event) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authContext.token,

            },
        };
        fetch(APIsURL + 'occasions', requestOptions)
            .then(response => response.json())
            .then(data => {
                const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                const formatedDateData = [];
                let thisItem = {};
                data.data.map((item)=>{
                    thisItem = item;
                    const d = new Date(item.date);
                    thisItem.formatedDate = months[d.getMonth()] + " " + d.getDate();
                    formatedDateData.push(thisItem);
                })
                setOccasions(data.data);
            })
            .catch(err => {
                {
                    console.log(err.message);
                }
            });
    }

    const addOccasion = (event) => {
       
        const formData = {};
        const d = new Date(date);
        
        formData.name = name;
        formData.description = description;
        formData.date = d.getFullYear() + "-" + d.getMonth() + "-" + d.getDate();

        console.log(formData);

        globContext.setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authContext.token,
                },
            body: JSON.stringify(formData)
        };

        fetch(APIsURL + 'occasions', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (!data.error) {
                    fetchOccasions();
                } else {
                    setErrorMessage("Upload Error, Please try again");
                }
                globContext.setLoading(false);
            })
            .catch(err => {
                console.log(err.message);
                globContext.setLoading(false);
            });
    }

    const deleteHandler = (id) => {
        confirmAlert({
            title: 'Confirm delete',
            message: 'Are you sure you want to delete this Occasion? this cannot be Undo!',
            buttons: [
                {
                    label: 'Delete',
                    onClick: () => deleteOccasion(id)
                },
                {
                    label: 'Cancel',
                    onClick: () => false
                }
            ]
        });
    };

    const deleteOccasion = (id) => {
        globContext.setLoading(true);
        const requestOptions = {
            method: 'Delete',
            headers: {
                'Authorization': 'Bearer ' + authContext.token,
                }
        };

        fetch(APIsURL + 'occasions/'+ id, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (!data.error) {
                    setErrorMessage("Successfully Deleted");
                    fetchOccasions();
                } else {
                    setErrorMessage("Delete Error, Please try again");
                }
                globContext.setLoading(false);
            })
            .catch(err => {
                console.log(err.message);
                globContext.setLoading(false);
            });
    }
    
    console.log("occasions", occasions);
    return(
        <div>
            <div>
                <TableContainer className={classes.dataTable} sx={{ minWidth: 650, maxWidth: '80%' }} component={Paper}>
                    <Table size="small" aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Description</TableCell>
                            <TableCell align="right">Date</TableCell>
                            <TableCell align="right">Delete</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {occasions.map((item) => {
                                return (
                                    <TableRow
                                        key={item.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {item.name}
                                        </TableCell>
                                        <TableCell align="right">
                                            {item.description}
                                        </TableCell>
                                        <TableCell align="right">
                                            {item.formatedDate}
                                        </TableCell>
                                        <TableCell align="right">
                                            <DeleteIcon className={classes.deleteIcon} color="primary" onClick={() => { deleteHandler(item.id); }} />
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                    
            </div>
            <div className={classes.formContainer}>
                <Input placeholder="Name" onChange={(event) => { setName(event.target.value) }} value={name} />
                <Input placeholder="Description" onChange={(event) => { setDescription(event.target.value) }} value={description} />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label="Date"
                        inputFormat="MMM dd"
                        value={date}
                        onChange={(event) => { setDate(event) }}
                        renderInput={(params) => <Input {...params} />}
                    />
                </LocalizationProvider>
                <Button onClick={addOccasion} color="primary" label="Add Occasion" />
            </div>
            <BoxLabel>{errorMessage}</BoxLabel>
        </div>
    );
}

export default Home;