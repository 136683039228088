import React from 'react';
import LoginFormTemplate from '../../Templates/Pages/LoginForm/LoginForm';

const LoginForm = () => {

    return(
        <LoginFormTemplate/>
    );
}

export default LoginForm