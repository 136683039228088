import React from 'react';
import { Button as OrgButton } from '@material-ui/core';

import classes from './UploadInput.module.css';



const Button = (props) => {

    return(
    <OrgButton {...props} className={classes.Button} color={props.color} component="label" variant="contained">
        {props.label}
        <input
            onChange={props.onChangeHandler}
            type="file"
            hidden
        />
    </OrgButton>
    );
};

export default Button;