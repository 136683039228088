import { Typography } from '@material-ui/core';
import React from 'react';

import classes from './BoxLabel.module.css';

const Button = (props) => (
    <div className={classes.BoxLabel}>
        <Typography {...props} >{props.children}</Typography>
    </div>
);

export default Button;