const devBackendBaseURL = "http://127.0.0.1:8000";
const devImagesURL = devBackendBaseURL;
const devAPIsURL = devBackendBaseURL + "/api/";

const prodBackendBaseURL = "https://e-cards.hewar.com.sa/backend/public";
const prodImagesURL = prodBackendBaseURL;
const prodAPIsURL = prodBackendBaseURL + "/api/";

const production = true;

export const imagesURL = production ? prodImagesURL : devImagesURL;
export const APIsURL = production ? prodAPIsURL : devAPIsURL;