import React, {useState} from "react";

const GlobContext = React.createContext({
    loadingHandler: () => {},
    loading: false,
    occasionIdHandler: () => {},
    occasionId: null
});

export const GlobContextProvider = (props) => {

    const [loading, setLoading] = useState(false);
    const [occasionId, setOccasionId] = useState(localStorage.getItem('occasionId'));
    
    const loadingHandler = (value) => {
        setLoading(value);
    }

    const occasionIdHandler = (value) => {
        localStorage.setItem('occasionId', value);
        setOccasionId(value);
    }

    const contextValue = {
        loading: loading,
        setLoading: loadingHandler,
        occasionId: occasionId,
        setOccasionId: occasionIdHandler
    }

    return(
        <GlobContext.Provider value={contextValue}>
            {props.children}
        </GlobContext.Provider>
    );
}

export default GlobContext;