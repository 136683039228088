import React, {useContext, useState} from 'react';
import classes from './loginForm.module.css';
import Input from '../../Components/Input/Input';
import Button from '../../Components/Button/Button';
import BoxLabel from '../../Components/BoxLabel/BoxLabel';
import AuthContext from '../../../store/auth-context';
import { useHistory } from 'react-router-dom';
import { APIsURL } from '../../../Constants';

const LoginForm = (props) =>  {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const authContext = useContext(AuthContext);
    const history = useHistory();

    const submitHandler = () => {
        // event.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                email: email,
                password: password
             })
        };
        fetch(APIsURL + 'login', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (!data.error) {
                    authContext.login(data.token, data.userInfo);
                    console.log("login api result", data);
                    history.replace('/');
                } else {
                    setErrorMessage("Invalid login, Please try again");
                }
            })
            .catch(err => {{
                console.log(err.message);
            }});
    }
    console.log("render")
    return (
        <div className={classes.Container}>
            <BoxLabel>Login</BoxLabel>
            <BoxLabel>{errorMessage}</BoxLabel>
            <Input label="Email" value={email} onInput={e => setEmail(e.target.value)}/>
            <Input type="password" label="Password" value={password} onInput={e => setPassword(e.target.value)}/>
            <Button color="default" label="Login" onClick={submitHandler} />
        </div>
    );
}

export default LoginForm;