import React, {useState} from "react";

const AuthContext = React.createContext({
    token: null,
    isLoggedIn: false,
    userInfo: {isAdmin: false, name: '', clientName: '', logo: '', primaryColor: '', secondaryColor: ''},
    loginHandler: (token) => {},
    logoutHandler: () => {}
});

export const AuthContextProvider = (props) => {

    const storedToken = localStorage.getItem('token');
    const storedUserInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : { isAdmin: false, name: '', clientName: '', logo: '', primaryColor: '', secondaryColor: ''};
    const [token, setToken] = useState(storedToken);
    const [userInfo, setUserInfo] = useState(storedUserInfo);

    const isLoggedIn = !!token;
    const loginHandler = (token, userInfo) => {
        setToken(token);
        setUserInfo(userInfo);
        localStorage.setItem('token', token);
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        console.log("login",userInfo);
    }
    const logoutHandler = () => {
        setToken(null);
        setUserInfo({ isAdmin: false, name: '', clientName: '', logo: '', primaryColor: '', secondaryColor: '' });
        localStorage.clear();
        // localStorage.removeItem('token');
        // localStorage.removeItem('userInfo');
    }
    const contextValue = {
        token: token,
        isLoggedIn: isLoggedIn,
        userInfo: userInfo,
        login: loginHandler,
        logout: logoutHandler
    }

    return(
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContext;