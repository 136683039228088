import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import classes from './SelectBox.module.css';


const SelectBox = ( props ) => {
    
    const inputClasses = [classes.InputElement];

    if (props.invalid) {
        inputClasses.push(classes.Invalid);
    }

    return (
        <FormControl variant="filled" fullWidth>
            <Select
                {...props} 
                size="small" 
                value={props.value} 
                className={inputClasses.join(' ')} 
                id={props.id}
            >
                <MenuItem value={"none"}>Position Set By User (click to make it fixed)</MenuItem>
                <MenuItem value={"topleft"}>Top Left</MenuItem>
                <MenuItem value={"topright"}>Top Right</MenuItem>
                <MenuItem value={"bottomleft"}>Bottom Left</MenuItem>
                <MenuItem value={"bottomright"}>Bottom Right</MenuItem>
                <MenuItem value={"bottomcenter"}>Bottom Center</MenuItem>
            </Select>
        </FormControl>
    );

};

export default SelectBox;