import React, {useState, useEffect, useContext} from 'react';
import AuthContext from '../../store/auth-context';
import GlobContext from '../../store/glob-context';
import Button from '@material-ui/core/Button';
import SelectBox from '../../Templates/Components/SelectBox/SelectBox';
import ButtonGroup from '../../Templates/Components/ButtonGroup/ButtonGroup';
import BoxLabel from '../../Templates/Components/BoxLabel/BoxLabel';
import MediaCard from '../../Templates/Components/MediaCard/MediaCard';
import CardsContainer from '../../Templates/Components/CardsContainer/CardsContainer';
import classes from './AddCard.module.css';
import UploadInput from '../../Templates/Components/UploadInput/UploadInput';
import { APIsURL, imagesURL} from '../../Constants';

const Home = () => {

    const authContext = useContext(AuthContext);
    const globContext = useContext(GlobContext);
    const [occasions, setOccasions] = useState([]);
    const [cards, setCards] = useState([]);
    const [position, setPosition] = useState("none");
    const [occasionId, setOccasionId] = useState(globContext.occasionId === "all" ? null : globContext.occasionId);
    const [showUploadButton, setShowUploadButton] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    console.log(globContext);
    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authContext.token,

             },
        };
        fetch(APIsURL + 'occasions', requestOptions)
            .then(response => response.json())
            .then(data => {
                setOccasions(data.data);
                // let recentOccasionId = null;
                // data.data.map((item) => {
                //     recentOccasionId = item.id;
                // })
                // setOccasionId(recentOccasionId);
            })
            .catch(err => {
                {
                    console.log(err.message);
                }
            });
        fetchCards();
    }, [occasionId]);

    const fetchCards = () => {

        if(!occasionId){
            return false;
        }
        globContext.setLoading(true);
        const id = occasionId;
        console.log(id);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authContext.token,
            },
        };
        const urlParams = id != 'all' ? '/occasion/'+id : '/occasion/all';
        fetch(APIsURL + 'cards'+urlParams, requestOptions)
            .then(response => response.json())
            .then(data => {
                setCards(data.data);
                setShowUploadButton(true);
                globContext.setLoading(false);
            })
            .catch(err => {
                console.log(err.message);
                setShowUploadButton(true);
                globContext.setLoading(false);
            });
    }

    const addCard = (event) => {
       
        const formData = new FormData();
        
        formData.append('image_src', event.target.files[0]);
        formData.append('occasion_id', occasionId);
        formData.append('position', position);

        globContext.setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + authContext.token,
                },
            body: formData
        };

        fetch(APIsURL + 'cards', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (!data.error) {
                    fetchCards();
                } else {
                    setErrorMessage("Upload Error, Please try again");
                }
                globContext.setLoading(false);
            })
            .catch(err => {
                console.log(err.message);
                globContext.setLoading(false);
            });
    }

    const deleteCard = (id) => {
        
        globContext.setLoading(true);
        const requestOptions = {
            method: 'Delete',
            headers: {
                'Authorization': 'Bearer ' + authContext.token,
                }
        };

        fetch(APIsURL + 'cards/'+ id, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (!data.error) {
                    setErrorMessage("Successfully Deleted");
                    fetchCards();
                } else {
                    setErrorMessage("Delete Error, Please try again");
                }
                globContext.setLoading(false);
            })
            .catch(err => {
                console.log(err.message);
                globContext.setLoading(false);
            });
    }
    
    console.log("occasions", occasions);
    console.log("cards", cards);
    return(
        <div>
            {!occasionId && <BoxLabel color="secondary">Select Occasion</BoxLabel>}
            <div>
                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                    {occasions.map((item) => {
                        return (
                            <Button color={occasionId == item.id ? "secondary" : "primary"} onClick={() => { globContext.setOccasionId(item.id); setOccasionId(item.id); }}>{item.name}</Button>
                            )
                    })}
                </ButtonGroup>
            </div>
            <CardsContainer>
                {cards.slice(0,3).map((item) => {
                    return (
                        <MediaCard deleteHandler={() => { deleteCard(item.id) }} isSelected={true} src={imagesURL + item.image_src} title=""/>
                    )
                })}
            </CardsContainer>
            {
            showUploadButton 
            && 
            <div className={classes.formContainer}>
                <SelectBox onChange={(event)=>{setPosition(event.target.value)}} value={position} label="Fixed Position" />
                <UploadInput size="small" onChangeHandler={addCard} color="primary" label="Upload Card" />
            </div>
            }  
            <BoxLabel>{errorMessage}</BoxLabel>
        </div>
    );
}

export default Home;