import React, {useState, useEffect, useContext} from 'react';
import AuthContext from '../../store/auth-context';
import GlobContext from '../../store/glob-context';
import Button from '@material-ui/core/Button';
import ButtonGroup from '../../Templates/Components/ButtonGroup/ButtonGroup';
import MediaCard from '../../Templates/Components/MediaCard/MediaCard';
import classes from './Home.module.css';
import Input from '../../Templates/Components/Input/Input';
import CustomButton from '../../Templates/Components/Button/Button';
import CardsContainer from '../../Templates/Components/CardsContainer/CardsContainer';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { useHistory } from 'react-router-dom';
import BoxLabel from '../../Templates/Components/BoxLabel/BoxLabel';
import { APIsURL, imagesURL } from '../../Constants';


const Home = () => {

    const authContext = useContext(AuthContext);
    const globContext = useContext(GlobContext);
    const [occasions, setOccasions] = useState([]);
    const [occasionId, setOccasionId] = useState(globContext.occasionId);
    const [cards, setCards] = useState([]);
    const [cardId, setCardId] = useState(null);
    const [hidePosition, setHidePosition] = useState(0);
    const [text, setText] = useState(authContext.userInfo.name);
    const [textColor, setTextColor] = useState("white");
    const [textSize, setTextSize] = useState("medium");
    const [textPosition, setTextPosition] = useState("bottomright");
    const [cardPreview, setCardPreview] = useState(null);

    const history = useHistory();

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authContext.token,

             },
        };
        fetch(APIsURL + 'occasions', requestOptions)
            .then(response => response.json())
            .then(data => {
                setOccasions(data.data);
                // let recentOccasionId = null;
                // data.data.map((item) => {
                //     recentOccasionId = item.id;
                // })
                // setOccasionId(recentOccasionId);
            })
            .catch(err => {
                {
                    console.log(err.message);
                }
            });
        fetchCards(occasionId);
    }, []);

    const fetchCards = (id) => {
        if(!id){
            return false;
        }
        globContext.setLoading(true);
        setOccasionId(id);
        globContext.setOccasionId(id);
        console.log(id);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authContext.token,
            },
        };
        const urlParams = id != 'all' ? '/occasion/'+id : '/occasion/all';
        fetch(APIsURL + 'cards'+urlParams, requestOptions)
            .then(response => response.json())
            .then(data => {
                setCards(data.data);
                if(data.data.length == 1){
                    setCardId(data.data[0].id);
                }
                globContext.setLoading(false);
            })
            .catch(err => {
                console.log(err.message);
                globContext.setLoading(false);
            });
    }

    const generateCard = () => {
        
        if(!cardId || !text){
            return false;
        }
        globContext.setLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authContext.token,
            },
        };
        fetch(APIsURL + 'cards/generate/'+cardId+'/'+text+'/'+textColor+'/'+textSize+'/'+textPosition, requestOptions)
            .then(response => response.json())
            .then(data => {
                setCardPreview(data.url);
                globContext.setLoading(false);
            })
            .catch(err => {
                console.log(err.message);
                globContext.setLoading(false);
            });
    }

    const downloadCard = () => {
        window.open(imagesURL + cardPreview, "_blank");
    }
    
    console.log("cards",cards);
    return(
        <div>
            <div>
                {!occasionId && <BoxLabel color="secondary">Select Occasion</BoxLabel>}
                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                    <Button color={occasionId == "all" ? "secondary" : "primary"} onClick={() => { fetchCards("all") }}>All</Button>
                    {occasions.map((item) => {
                        return (
                            <Button color={occasionId == item.id ? "secondary" : "primary"} onClick={() => {fetchCards(item.id)}}>{item.name}</Button>
                            )
                    })}
                    {authContext.userInfo.isAdmin && <Button color="secondary" onClick={() => { history.replace('/ManageOccasions'); }} src={imagesURL + "AddImageIcon"} title="" >+</Button>}
                </ButtonGroup>
            </div>
            <CardsContainer>
                {(cards.length < 1 && !authContext.userInfo.isAdmin) ? <div className={classes.emptyCardsMessage}>No cards available for the selected occation</div>: ""}
                {cards.slice(0,3).map((item) => {
                    const isSelected = (cardId != item.id) ? false : true;
                    const src = (cardPreview && (cardId == item.id)) ? imagesURL + cardPreview : imagesURL + item.image_src;
                    return (
                        <MediaCard hideDeleteIcon={true} isSelected={isSelected} className={classes.selectedCard} onClick={() => { setCardPreview(null); setCardId(item.id); setHidePosition(item.is_fixed_position); setTextPosition(item.position == "none"? "bottomright": item.position)}} src={src} title="" />
                    )
                })}
                {authContext.userInfo.isAdmin ?  <div className={classes.addCardButtonContainer}>
                    <AddBoxIcon className={classes.addCardButton} color="secondary" onClick={() => { setCardPreview(null); history.replace('/AddCard'); }} src={imagesURL + "AddImageIcon"} title="" />
                </div> : ""}
            </CardsContainer>
                
            <div className={classes.formContainer}>
                <Input onChange={(event)=>{setText(event.target.value)}} value={text}  />
                <ButtonGroup className={classes.formElement} variant="text" color="secondary" aria-label="">
                    <Button disabled={(textColor == "white")? "disabled" : false} onClick={() => { setTextColor("white") }}>White</Button>
                    <Button disabled={(textColor == "black") ? "disabled" : false} onClick={() => { setTextColor("black") }}>Black</Button>
                </ButtonGroup>
                <ButtonGroup className={classes.formElement} variant="text" color="secondary" aria-label="">
                    <Button disabled={(textSize == "small")? "disabled" : false} onClick={() => { setTextSize("small") }}>Small</Button>
                    <Button disabled={(textSize == "medium") ? "disabled" : false} onClick={() => { setTextSize("medium") }}>Medium</Button>
                    <Button disabled={(textSize == "large") ? "disabled" : false} onClick={() => { setTextSize("large") }}>Large</Button>
                </ButtonGroup>
                {
                !hidePosition 
                && 
                <ButtonGroup className={classes.formElement} variant="text" color="secondary" aria-label="">
                    <Button disabled={(textPosition == "topleft") ? "disabled" : false} onClick={() => { setTextPosition("topleft") }}>Top-Left</Button>
                    <Button disabled={(textPosition == "topright") ? "disabled" : false} onClick={() => { setTextPosition("topright") }}>Top-Right</Button>
                    <Button disabled={(textPosition == "bottomleft") ? "disabled" : false} onClick={() => { setTextPosition("bottomleft") }}>Bottom-Left</Button>
                    <Button disabled={(textPosition == "bottomright") ? "disabled" : false} onClick={() => { setTextPosition("bottomright") }}>Bottom-Right</Button>
                    <Button disabled={(textPosition == "bottomcenter") ? "disabled" : false} onClick={() => { setTextPosition("bottomcenter") }}>Bottom-Center</Button>
                </ButtonGroup>
                }
                <CustomButton onClick={generateCard} color="primary" label="Preview" />
                <a href={cardPreview ? imagesURL + cardPreview : "#"} download={cardPreview ? true : false} ><CustomButton color="primary" label="Download" /></a>
            </div>
        </div>
    );
}

export default Home;